.wrapper {
  --mol-fe-paywall-inline-primary: #004db3;
  --mol-fe-paywall-inline-primary-dark: #1e3264;
  --mol-fe-paywall-inline-text: #fff;
  --mol-fe-paywall-inline-login-text: #1a1a1a;
  --mol-fe-paywall-inline-login-link: #004db3;
  --mol-fe-paywall-inline-button-background: #fff;
  --mol-fe-paywall-inline-button-color: #004db3;

  font-family: var(--mol-default-font-family);
  font-weight: 600;
  position: relative;
  box-sizing: border-box;

  &.thisismoney {
    --mol-fe-paywall-inline-primary: #451344;
    --mol-fe-paywall-inline-primary-dark: #5c395a;
    --mol-fe-paywall-inline-button-color: #451344;
  }

  &.primeTheme {
    --mol-fe-paywall-inline-button-color: #000;
    --mol-fe-paywall-prime-bg: #801a72;
    --mol-fe-paywall-prime-bg-dark: #201333;
  }
}

.signUp {
  width: 100%;
  background: linear-gradient(118deg, var(--mol-fe-paywall-inline-primary-dark) 4.18%, var(--mol-fe-paywall-inline-primary) 64.65%);
}

.signUpInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px;
  box-sizing: border-box;

  .primeTheme & {
    background: linear-gradient(140deg, var(--mol-fe-paywall-prime-bg) 0.46%, var(--mol-fe-paywall-prime-bg-dark) 86.46%);
  }
}

.signUpTopStripText {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 50px;
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  box-sizing: border-box;

  .summerTheme & {
    background: linear-gradient(160deg, #de3a54 20%, #fb9b30 100%);
  }

  .primeTheme & {
    background: linear-gradient(300deg, #214ca8 13%, #1f356c 67%);
  }
}

.isMobile .signUpTopStripText + .signUpInner {
  padding-top: 32px;
}

.logo {
  width: 162px;
  height: 42px;

  .thisismoney & {
    width: 172px;
    height: 64px;
  }

  .primeTheme & {
    width: 178px;
    height: 46px;
  }
}

.titleLogo {
  width: auto;
  height: 1em;
  display: inline;
  position: relative;
  top: 0.1em;
}

.signUpTitle,
.signUpPretitle {
  margin-top: 20px;
  color: var(--mol-fe-paywall-inline-text);
  font-size: 28px;
  line-height: 1.3;
  text-align: center;
}

.signUpPretitle {
  font-weight: 400;
}

.signUpTitle {
  font-weight: 600;
}

.signUpPretitle + .signUpTitle {
  margin-top: 0;
}

.signUpButton {
  margin-top: 20px;
  width: 100%;
  max-width: 330px;
  min-height: 58px;
  padding: 8px 18px;
  box-sizing: border-box;
  background: var(--mol-fe-paywall-inline-button-background);
  color: var(--mol-fe-paywall-inline-button-color);
  text-decoration: none;
  font-size: 20px;
  text-align: center;
  border-radius: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.9;
    text-decoration: none !important;
  }
}

.signUpButtonSubtitle {
  font-weight: 400;
  font-size: 14px;
}

.gradient {
  position: absolute;
  top: -100px;
  width: 100%;
  height: 100px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 1) 100%);

  &.shortGradient {
    top: -40px;
    height: 40px;
  }
}

.signUpBullets {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 360px;
  margin-top: 20px;
}

.signUpBullet {
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
  background: url("./assets/tick.svg");
  background-size: 15px 12px;
  background-repeat: no-repeat;
  background-position: left top 5px;
  color: var(--mol-fe-paywall-inline-text);
  font-size: 16px;
  font-weight: 600;

  .thisismoney & {
    background-image: url("./assets/tickYellow.svg");
  }

  .bulletsDisc &,
  .thisismoney .bulletsDisc & {
    background-image: url("./assets/disc.svg");
    background-size: 8px 8px;
    background-position: left 8px top 6px;
  }

  .primeTheme & {
    background-image: url("./assets/tickWhite.svg");
    font-weight: 400;
  }
}

.login {
  text-align: center;
  font-size: 14px;
  padding: 14px 0;
  color: var(--mol-fe-paywall-inline-login-text);
  font-weight: 400;
}

.loginLink {
  text-decoration: none;
  color: var(--mol-fe-paywall-inline-login-link);
  font-weight: 600;
}

.strikeThrough {
  text-decoration: line-through;
  color: #999;
}

.lighter {
  font-weight: lighter;
}

.blackFridaySale {
  display: block;
  height: auto;
  width: 90%;
  max-width: 450px;
  margin: 0 auto;
}

:global(.mol-desktop) {
  .logo {
    width: 182px;
    height: 46px;
  }

  .thisismoney .logo {
    width: 172px;
    height: 64px;
  }

  .primeTheme .logo {
    width: 200px;
    height: 52px;
  }

  .signUpTitle,
  .signUpPretitle {
    font-size: 28px;
  }

  .signUpButton {
    max-width: 350px;
    font-size: 22px;
  }

  .signUpButtonSubtitle {
    font-size: 16px;
  }

  .signUpBullets {
    max-width: 400px;
  }

  .signUpBullet {
    font-size: 18px;
  }

  .login {
    font-size: 16px;
  }
}
