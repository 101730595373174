:global {
  div#floating-share-bar-container {
    height: 49px;
    display: none;
    border-top: none;
    border-bottom: 1px solid #e3e2e3;
    background-color: #fff;
    z-index: 1002;
    padding: 7px 0;

    ul {
      margin-bottom: 0;
      padding: 0;

      li {
        width: 35px;
        height: 35px;

        &.share-facebook {
          background: url('../assets/share-facebook-square.svg') no-repeat 0;
          background-position: center;

          &:hover {
            background: url('../assets/share-facebook-square.svg') no-repeat 0;
            opacity: 0.8;
          }
        }

        &.share-facebook-long {
          background: url('../assets/share-facebook-lge.svg') no-repeat 0;
          width: 100px;

          &:hover {
            background: url('../assets/share-facebook-lge.svg') no-repeat 0;
            opacity: 0.8;
          }
        }

        &.share-twitter {
          background: url('../assets/share-twitter.svg') no-repeat 0;
          height: 35px;
          width: 35px;

          &:hover {
            opacity: 0.8;
          }
        }

        &.share-twitter-long {
          background: url('../assets/share-twitter-long.svg') no-repeat 0;
          height: 35px;
          width: 100px;

          &:hover {
            opacity: 0.8;
          }
        }

        &.share-fbmessenger {
          width: 35px;
          height: 35px;
          background: url('../assets/share-messenger.svg') no-repeat 0;

          &:hover {
            opacity: 0.8;
            background: url('../assets/share-messenger.svg') no-repeat 0;
          }
        }

        &.share-email {
          background: url('../assets/share-email.svg') no-repeat 0;

          &:hover {
            background: url('../assets/share-email.svg') no-repeat 0;
            opacity: 0.8;
          }
        }

        a {
          width: 35px;
          height: 35px;
        }
      }
    }

    &.sticky {
      height: 35px;
      display: block;
      position: fixed;
      top: 0;
      margin-top: 0;
      width: inherit;
    }

    &.hide-on-comments-visible {
      display: none !important;
    }

    a.comments-count {
      padding-top: 0;
      width: auto;

      span {
        transform: scale(0.9);
        margin-top: 5px;
      }

      .count-text {
        width: 96px;
        font-size: 11px;
        font-family: var(--mol-default-font-family);
        letter-spacing: -0.42px;
        color: #004ab6;
        margin-top: -4px;
      }

      .count-number {
        height: unset;
        font-size: 25px;
        font-family: var(--mol-default-font-family);
        font-weight: 700;
        font-style: normal;
        color: #004ab6;
        min-height: 25px;
      }
    }

    .floating-buttons-bar {
      position: relative;
      display: flex;
      float: right;

      a.back-to-top {
        width: auto;
        height: 35px;
        float: left;
        display: flex;
        margin-right: 4px;
        align-items: center;
        text-decoration: none;

        &::before {
          content: '';
          width: 1px;
          height: 35px;
          margin: 0 10px;
          background-color: #e3e2e3;
          display: block;
        }

        span.top-chevron {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 84px;
          height: 35px;
          background-color: #6cf7ff;
          text-decoration: none;
          font-family: var(--mol-default-font-family);
          font-weight: 500;
          font-style: normal;
          font-size: 12px;
          text-align: center;
          color: #000;

          &::after {
            border-style: solid;
            border-width: 0.25em 0.25em 0 0;
            content: '';
            display: inline-block;
            height: 0.85em;
            left: 0.15em;
            position: relative;
            top: 0.27em;
            vertical-align: top;
            width: 0.85em;
            margin: 0 6px;
            transform: rotate(-42deg) skew(13deg, 7deg);
          }
        }
      }

      .back-to-home {
        display: block;
        height: 35px;
        width: 84px;
        background: #004db3 url('../assets/backToHome.png') no-repeat 50px 9px;
        margin-left: 4px;
        text-decoration: none;

        span {
          color: white;
          height: 35px;
          text-align: center;
          line-height: 35px;
          position: relative;
          left: 10px;
          font-family: var(--mol-default-font-family);
          font-size: 12px;
        }

        &.tim-bgr {
          background-color: #441543;
        }

        &.new-articles {
          position: relative;
          background-position: 50px 14px;

          .new-article-count {
            height: 23px;
            width: 23px;
            left: 59px;
            top: 3px;
            font-family: var(--mol-default-font-family);
            font-size: 11px;
          }
        }

        &.hidden {
          display: none;
        }
      }
    }
  }

  #articleIconLinksContainer {
    overflow: hidden;
  }

  .extraWide #floating-share-bar-container {
    width: 964px !important;
  }

  .wide #floating-share-bar-container {
    width: 636px !important;
  }
}
